/deep/ .el-dialog__body {
  text-align: left !important; }

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: solid 1px #dcdfe6; }

/deep/ .el-tab-pane {
  padding-right: 15px; }

/deep/ .el-tabs__content {
  padding-left: 10px; }

/deep/ .el-collapse-item__header {
  background-color: #f1f6fc;
  padding-left: 8px; }

/deep/ .el-collapse-item__content {
  padding: 20px 0 10px; }

/deep/ .el-collapse {
  border-bottom: none; }

.trigger /deep/ .el-collapse-item__wrap {
  border-bottom: none; }
